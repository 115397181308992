import React, { Component } from "react";

import "./Map.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { mobileCheck } from "../../utils";
import Slider from "react-slick";

class Map extends Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        var isMobile = mobileCheck();

        const { handleClick, handleHover, timeStatuses } = this.props;

        // if (isMobile) {
        //     return (
        //         <div className="Map-slider Map-container">
        //             <div className="Map Slider">
        //                 <Slider {...settings}>
        //                     <button
        //                         className={`Map-block Map-block-1 ${
        //                             timeStatuses[1].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("1")}
        //                         onMouseOver={() => handleHover("1")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map1</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-2 ${
        //                             timeStatuses[2].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("2")}
        //                         onMouseOver={() => handleHover("2")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map2</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-3 ${
        //                             timeStatuses[3].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("3")}
        //                         onMouseOver={() => handleHover("3")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map3</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-4 ${
        //                             timeStatuses[4].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("4")}
        //                         onMouseOver={() => handleHover("4")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map4</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-5 ${
        //                             timeStatuses[5].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("5")}
        //                         onMouseOver={() => handleHover("5")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map5</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-6 ${
        //                             timeStatuses[6].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("6")}
        //                         onMouseOver={() => handleHover("6")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map6</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-7 ${
        //                             timeStatuses[7].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("7")}
        //                         onMouseOver={() => handleHover("7")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map7</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-8 ${
        //                             timeStatuses[8].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("8")}
        //                         onMouseOver={() => handleHover("8")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map8</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-9 ${
        //                             timeStatuses[9].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("9")}
        //                         onMouseOver={() => handleHover("9")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map9</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-10 ${
        //                             timeStatuses[10].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("10")}
        //                         onMouseOver={() => handleHover("10")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map10</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-11 ${
        //                             timeStatuses[11].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("11")}
        //                         onMouseOver={() => handleHover("11")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map11</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-12 ${
        //                             timeStatuses[12].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("12")}
        //                         onMouseOver={() => handleHover("12")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map12</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-13 ${
        //                             timeStatuses[13].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("13")}
        //                         onMouseOver={() => handleHover("13")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map13</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-14 ${
        //                             timeStatuses[14].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("14")}
        //                         onMouseOver={() => handleHover("14")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map14</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-16 ${
        //                             timeStatuses[15].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("15")}
        //                         onMouseOver={() => handleHover("15")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map16</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-15 ${
        //                             timeStatuses[16].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("16")}
        //                         onMouseOver={() => handleHover("16")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map15</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-17 ${
        //                             timeStatuses[17].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("17")}
        //                         onMouseOver={() => handleHover("17")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map17</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-18 ${
        //                             timeStatuses[18].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("18")}
        //                         onMouseOver={() => handleHover("18")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map18</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-19 ${
        //                             timeStatuses[19].completed
        //                                 ? "completed"
        //                                 : ""
        //                         }`}
        //                         onClick={() => handleClick("19")}
        //                         onMouseOver={() => handleHover("19")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map19</span>
        //                     </button>
        //                     <button
        //                         className={`Map-block Map-block-20 ${
        //                             timeStatuses[20].completed ? "completed" : ""
        //                         }`}
        //                         onClick={() => handleClick("20")}
        //                         onMouseOver={() => handleHover("20")}
        //                         onMouseOut={() => handleHover("")}
        //                     >
        //                         <span>Map20</span>
        //                     </button>
        //                 </Slider>
        //             </div>
        //         </div>
        //     );
        // }

        return (
            <div className="Map-container Map-container--animated">
                <div className="Map">
                    <div className="Map-content">
                        <div className="Map-line">
                            <button
                                className={`Map-block Map-block-1 ${
                                    timeStatuses[17].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("17")}
                                onMouseOver={() => handleHover("17")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map17</span>
                            </button>
                            <button
                                className={`Map-block Map-block-2 ${
                                    timeStatuses[18].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("18")}
                                onMouseOver={() => handleHover("18")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map18</span>
                            </button>
                            <button
                                className={`Map-block Map-block-3 ${
                                    timeStatuses[19].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("19")}
                                onMouseOver={() => handleHover("19")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map19</span>
                            </button>
                        </div>
                        <div className="Map-line">
                            <button
                                className={`Map-block Map-block-4 ${
                                    timeStatuses[16].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("16")}
                                onMouseOver={() => handleHover("16")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map16</span>
                            </button>
                            <button
                                className={`Map-block Map-block-5 ${
                                    timeStatuses[8].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("8")}
                                onMouseOver={() => handleHover("8")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map8</span>
                            </button>
                            <button
                                className={`Map-block Map-block-6 ${
                                    timeStatuses[9].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("9")}
                                onMouseOver={() => handleHover("9")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map9</span>
                            </button>
                            <button
                                className={`Map-block Map-block-7 ${
                                    timeStatuses[10].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("10")}
                                onMouseOver={() => handleHover("10")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map10</span>
                            </button>
                        </div>
                        <div className="Map-line Map-line--2">
                            <button
                                className={`Map-block Map-block-8 ${
                                    timeStatuses[1].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("1")}
                                onMouseOver={() => handleHover("1")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map1</span>
                            </button>
                            <button
                                className={`Map-block Map-block-9 ${
                                    timeStatuses[2].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("2")}
                                onMouseOver={() => handleHover("2")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map2</span>
                            </button>
                            <button
                                className={`Map-block Map-block-10 ${
                                    timeStatuses[3].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("3")}
                                onMouseOver={() => handleHover("3")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map3</span>
                            </button>
                            <button
                                className={`Map-block Map-block-11 ${
                                    timeStatuses[11].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("11")}
                                onMouseOver={() => handleHover("11")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map11</span>
                            </button>
                            <button
                                className={`Map-block Map-block-12 ${
                                    timeStatuses[20].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("20")}
                                onMouseOver={() => handleHover("20")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map20</span>
                            </button>
                        </div>
                        <div className="Map-line Map-line--3">
                            <button
                                className={`Map-block Map-block-13 ${
                                    timeStatuses[7].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("7")}
                                onMouseOver={() => handleHover("7")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map7</span>
                            </button>
                            <button
                                className={`Map-block Map-block-14 ${
                                    timeStatuses[6].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("6")}
                                onMouseOver={() => handleHover("6")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map6</span>
                            </button>
                            <button
                                className={`Map-block Map-block-16 ${
                                    timeStatuses[5].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("5")}
                                onMouseOver={() => handleHover("5")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map5</span>
                            </button>
                            <button
                                className={`Map-block Map-block-15 ${
                                    timeStatuses[4].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("4")}
                                onMouseOver={() => handleHover("4")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map4</span>
                            </button>
                            <button
                                className={`Map-block Map-block-17 ${
                                    timeStatuses[12].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("12")}
                                onMouseOver={() => handleHover("12")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map12</span>
                            </button>
                        </div>
                        <div className="Map-line">
                            <button
                                className={`Map-block Map-block-18 ${
                                    timeStatuses[15].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("15")}
                                onMouseOver={() => handleHover("15")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map15</span>
                            </button>
                            <button
                                className={`Map-block Map-block-19 ${
                                    timeStatuses[14].completed
                                        ? "completed"
                                        : ""
                                }`}
                                onClick={() => handleClick("14")}
                                onMouseOver={() => handleHover("14")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map14</span>
                            </button>
                            <button
                                className={`Map-block Map-block-20 ${
                                    timeStatuses[13].completed ? "completed" : ""
                                }`}
                                onClick={() => handleClick("13")}
                                onMouseOver={() => handleHover("13")}
                                onMouseOut={() => handleHover("")}
                            >
                                <span>Map13</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Map;

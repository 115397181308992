import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes';
import * as ReactGA from '../../GA';
import { EPISODES } from '../../Episodes';

import Map from '../Map/Map';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import './Home.scss';
import End from '../End/End';

class Home extends Component {

    componentDidMount() {
        ReactGA.pageview(`/map`);
        this.props.AppStore.updateChars(this.props.match.params.type);
        this.props.AppStore.setRoute('/map');
    }
    // componentDidUpdate() {
    //     if(this.props.AppStore.state.firstMapLoad) {
    //         this.props.AppStore.changeMapLoadingState(false);
    //     }
    // }

    componentWillUnmount() {
        this.props.AppStore.closePlayer();
    }
  
    render() {

        const { AppStore } = this.props;

        const backgroundPoster = EPISODES[AppStore.state.lang][AppStore.state.hoverMap] ? EPISODES[AppStore.state.lang][AppStore.state.hoverMap].poster : '';

        const closePlayerVisibility = {
            opacity: AppStore.state.mouseMoving ? '1' : '0',
        };
        
        const viewPecentage = AppStore.state.hoverMap
            ?
                AppStore.state.timeStatuses[AppStore.state.hoverMap].normal * 100 / AppStore.state.timeStatuses[AppStore.state.hoverMap].totalDuration
            :
                0;

        return (
            <article
                className="Home"
                onMouseMove={(e) => AppStore.setMouseMove(e)}
            >
                <div className="Page">
                    {AppStore.state.hoverMap && (
                        <div className="Home-episode-indicator">
                            <em>{AppStore.state.hoverMap}°</em> Arrondissement
                            {/* { AppStore.state.timeStatuses[AppStore.state.hoverMap].normal > 0 && */}
                            <div className="Home-episode-viewed-indicator">
                                <span>
                                    {AppStore.state.timeStatuses[
                                        AppStore.state.hoverMap
                                    ].completed
                                        ? "✅"
                                        : (Math.floor(viewPecentage) || 0) + " %"}
                                </span>
                                <div
                                    className="Home-episode-viewed-indicator-bar"
                                    style={{
                                        width:
                                            AppStore.state.timeStatuses[
                                                AppStore.state.hoverMap
                                            ].completed ? '100%' : viewPecentage + "%",
                                    }}
                                />
                            </div>
                            {/* } */}
                        </div>
                    )}


                    {/* hide background when hovering map */}
                    {/* {AppStore.state.hoverMap && (
                        <div
                            className="Home-episode-background"
                            style={{
                                backgroundImage: `url(${backgroundPoster}`,
                            }}
                        />
                    )} */}

                    {AppStore.state.completed && <End AppStore={AppStore} />}

                    {!AppStore.state.completed && (
                        <Map
                            handleClick={AppStore.handlePlaying}
                            handleHover={AppStore.hoverMap}
                            timeStatuses={AppStore.state.timeStatuses}
                        />
                    )}

                    {AppStore.state.playing && (
                        <div className="Home-player">
                            <div
                                style={closePlayerVisibility}
                                className="Home-player-close"
                                onClick={AppStore.closePlayer}
                            >
                                <span>Chiudi</span>
                            </div>
                            <VideoPlayer
                                {...AppStore.state.videoJsOptions}
                                onUpdate={AppStore.updateTimeStatus}
                                episode={AppStore.state.episode}
                                checkDuration={AppStore.checkDuration}
                                onEnd={AppStore.onEnded}
                                timeStamp={
                                    AppStore.state.timeStatuses[
                                        AppStore.state.episode.toLocaleLowerCase()
                                    ].normal
                                }
                            />
                            <div
                                className="Home-player-overlay"
                                onClick={AppStore.closePlayer}
                            ></div>
                        </div>
                    )}
                </div>
            </article>
        );
    }
}

export default Home;
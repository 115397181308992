import poster1 from './assets/poster1.jpg';
import poster2 from './assets/poster2.jpg';
import poster3 from './assets/poster3.jpg';
import poster4 from './assets/poster4.jpg';
import poster5 from './assets/poster5.jpg';
import poster6 from './assets/poster6.jpg';
import poster7 from './assets/poster7.jpg';
import poster8 from './assets/poster8.jpg';
import poster9 from './assets/poster9.jpg';
import poster10 from './assets/poster10.jpg';
import poster11 from './assets/poster11.jpg';
import poster12 from './assets/poster12.jpg';
import poster13 from './assets/poster13.jpg';
import poster14 from './assets/poster14.jpg';
import poster15 from './assets/poster15.jpg';
import poster16 from './assets/poster16.jpg';
import poster17 from './assets/poster17.jpg';
import poster18 from './assets/poster18.jpg';
import poster19 from './assets/poster19.jpg';
import poster20 from './assets/poster20.jpg';

const CDN_BASE = process.env.REACT_APP_CDN;

export const EPISODES = {
    it: {
        1: {
            poster: poster1,
            src: `${CDN_BASE}/video/1/1.mp4`,
        },
        2: {
            poster: poster2,
            src: `${CDN_BASE}/video/2/2.mp4`,
        },
        3: {
            poster: poster3,
            src: `${CDN_BASE}/video/3/3.mp4`,
        },
        4: {
            poster: poster4,
            src: `${CDN_BASE}/video/4/4.mp4`,
        },
        5: {
            poster: poster5,
            src: `${CDN_BASE}/video/5/5.mp4`,
        },
        6: {
            poster: poster6,
            src: `${CDN_BASE}/video/6/6.mp4`,
        },
        7: {
            poster: poster7,
            src: `${CDN_BASE}/video/7/7.mp4`,
        },
        8: {
            poster: poster8,
            src: `${CDN_BASE}/video/8/8.mp4`,
        },
        9: {
            poster: poster9,
            src: `${CDN_BASE}/video/9/9.mp4`,
        },
        10: {
            poster: poster10,
            src: `${CDN_BASE}/video/10/10.mp4`,
        },
        11: {
            poster: poster11,
            src: `${CDN_BASE}/video/11/11.mp4`,
        },
        12: {
            poster: poster12,
            src: `${CDN_BASE}/video/12/12.mp4`,
        },
        13: {
            poster: poster13,
            src: `${CDN_BASE}/video/13/13.mp4`,
        },
        14: {
            poster: poster14,
            src: `${CDN_BASE}/video/14/14.mp4`,
        },
        15: {
            poster: poster15,
            src: `${CDN_BASE}/video/15/15.mp4`,
        },
        16: {
            poster: poster16,
            src: `${CDN_BASE}/video/16/16.mp4`,
        },
        17: {
            poster: poster17,
            src: `${CDN_BASE}/video/17/17.mp4`,
        },
        18: {
            poster: poster18,
            src: `${CDN_BASE}/video/18/18.mp4`,
        },
        19: {
            poster: poster19,
            src: `${CDN_BASE}/video/19/19.mp4`,
        },
        20: {
            poster: poster20,
            src: `${CDN_BASE}/video/20/20.mp4`,
        },
    },
    en: {
        1: {
            poster: poster1,
            src: `${CDN_BASE}/video/en/1/1.mp4`,
        },
        2: {
            poster: poster2,
            src: `${CDN_BASE}/video/en/2/2.mp4`,
        },
        3: {
            poster: poster3,
            src: `${CDN_BASE}/video/3/3.mp4`,
        },
        4: {
            poster: poster4,
            src: `${CDN_BASE}/video/en/4/4.mp4`,
        },
        5: {
            poster: poster5,
            src: `${CDN_BASE}/video/en/5/5.mp4`,
        },
        6: {
            poster: poster6,
            src: `${CDN_BASE}/video/6/6.mp4`,
        },
        7: {
            poster: poster7,
            src: `${CDN_BASE}/video/7/7.mp4`,
        },
        8: {
            poster: poster8,
            src: `${CDN_BASE}/video/8/8.mp4`,
        },
        9: {
            poster: poster9,
            src: `${CDN_BASE}/video/en/9/9.mp4`,
        },
        10: {
            poster: poster10,
            src: `${CDN_BASE}/video/10/10.mp4`,
        },
        11: {
            poster: poster11,
            src: `${CDN_BASE}/video/en/11/11.mp4`,
        },
        12: {
            poster: poster12,
            src: `${CDN_BASE}/video/12/12.mp4`,
        },
        13: {
            poster: poster13,
            src: `${CDN_BASE}/video/en/13/13.mp4`,
        },
        14: {
            poster: poster14,
            src: `${CDN_BASE}/video/14/14.mp4`,
        },
        15: {
            poster: poster15,
            src: `${CDN_BASE}/video/15/15.mp4`,
        },
        16: {
            poster: poster16,
            src: `${CDN_BASE}/video/16/16.mp4`,
        },
        17: {
            poster: poster17,
            src: `${CDN_BASE}/video/17/17.mp4`,
        },
        18: {
            poster: poster18,
            src: `${CDN_BASE}/video/18/18.mp4`,
        },
        19: {
            poster: poster19,
            src: `${CDN_BASE}/video/en/19/19.mp4`,
        },
        20: {
            poster: poster20,
            src: `${CDN_BASE}/video/20/20.mp4`,
        },
    },
};
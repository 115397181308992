import React, { Component } from 'react';

import './Header.scss';

import Logo from '../../assets/reportage-bizarre.png';
import LogoDino from '../../assets/dinosaur.svg';

class Header extends Component {

    render() {

        const { AppStore } = this.props;

        const headerVisibility = {
            opacity: AppStore.state.mouseMoving ? '1' : '0',
        };

        return (
            <header style={headerVisibility} className={`Header ${AppStore.state.playing ? 'Header--active' : ''}`}>
                <div className="Logo">
                    <img src={Logo} className="Logo-img" alt="Reportage Bizarre" />
                    <img src={LogoDino} className="Logo-img Logo-img--dino" alt="Reportage Bizarre" />
                </div>
                {AppStore.state.playing &&
                    <div className="Header-episode-indicator">
                        <em>{AppStore.state.episode}°</em> Arrondissement
                    </div>
                }
            </header>
        );
    }
}

export default Header;
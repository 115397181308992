import React from 'react';
import {Switch, Route } from 'react-router-dom';
import { Subscribe } from 'unstated';
import AppContainer from './AppContainer';
import {
	CSSTransition,
	TransitionGroup,
  } from 'react-transition-group';

import Home from './Components/Home/Home';
import Intro from './Components/Intro/Intro';
import Header from './Components/Header/Header';

import AudioPlayer from './Components/AudioPlayer/AudioPlayer';

export const ROUTES = {
    INTRO: '/',
    HOME: '/map/:type',
    CREDITS: '/credits',
};

export default function routes(props) {
    return (
        <Subscribe to={[AppContainer]}>
            {(AppStore) =>
                <>

                    { AppStore.state.route !== '/' ?
                        <Header {...props} AppStore={AppStore} />
                        :
                        ''
                    }

                    <AudioPlayer {...props} AppStore={AppStore} />

                    <Route
                    render={({ location }) => (
                            <TransitionGroup>
                                <CSSTransition
                                key={location.pathname}
                                classNames="fade"
                                timeout={600}
                                >
                                <Switch location={location}>
                                    <Route exact path={ROUTES.INTRO}
                                        render={(props) => <Intro {...props} AppStore={AppStore} />}
                                        />
                                    <Route exact path={ROUTES.HOME}
                                        render={(props) => <Home {...props} AppStore={AppStore} />}
                                        />
                                    {/*
                                    <Route
                                        path="/indagati/:type"
                                        render={(props) => <Investigated {...props} AppStore={AppStore} />}
                                        />
                                    <Route exact path={ROUTES.CREDITS} component={Credits} />
                                    */}
                                </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        )}
                    />

                </>
            }
        </Subscribe>
    );
}

import React, { Component } from 'react';
import { ROUTES } from '../../routes';
import { isMobile, isIOS } from "react-device-detect";
import * as ReactGA from '../../GA';
import Artchivio from '../../assets/artchivio.svg';

import './Intro.scss';

class Intro extends Component {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
    }
    state = {
        percent: 0,
        show: 'langs'
    }

    componentDidMount() {
        ReactGA.pageview(`/`);
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() { 
        window.removeEventListener('load', this.handleLoad)  
        clearTimeout(this.stopLoading);
        clearTimeout(this.startAudio);
        clearTimeout(this.goToMap);
    }

    handleLoad() {
        this.setState({
            percent: 100
        });

        this.stopLoading = setTimeout(() => {
            this.props.AppStore.changeLoadingState(false);
        }, 3000);
    }

    selectLang = (lang) => {
        this.props.AppStore.setLang(lang);

        this.setState({
            show: 'phrase'
        });
        
        this.startAudio = setTimeout(() => {
            this.props.AppStore.changeAudioStatus(true);
        }, 2000);
    }

    startReportage = () => {
        this.setState({
            show: "logo",
        });

        this.goToMap = setTimeout(() => {
            this.props.history.push("/map/all");
            this.props.AppStore.setRoute("/map/all");
        }, 4500);
    }


    render() {

        const { percent, show } = this.state;
        const { loading } = this.props.AppStore.state;

        const introText =
            this.props.AppStore.state.lang === "it" ? (
                <>
                    <p>
                        Ho approcciato la città come un esploratore in un paese
                        esotico.
                        <br />
                        Era il febbraio del 2014 e avevo bisogno di disimparare
                        tutto quello che avevo capito alla scuola di cinema.
                    </p>
                    <p>
                        Dalla mattina alla sera ho vagato per i venti quartieri
                        della città filmando con una piccolissima fotocamera.
                    </p>
                    <p>
                        Molte persone mi hanno aiutato in quelle settimane e a
                        ognuna di esse domandavo: conosci qualcosa di
                        bizzarro a Parigi?
                    </p>
                    <p>
                        <button onClick={this.startReportage}>Inziamo</button>
                    </p>
                </>
            ) : (
                <>
                    <p>
                        I have approched the city like an explorer in an exotic
                        country.
                        <br />
                        It was February 2014 and I needed to unlearn everything
                        that I learnt at the film school.
                    </p>
                    <p>
                        From morning to night I have wandered the 20
                        neighbourhoods of the city filming with a very small
                        camera.
                    </p>
                    <p>
                        Many people helped me during those weeks and to everyone
                        of them I have asked: do you know something bizarre
                        about Paris?
                    </p>
                    <p>
                        <button onClick={this.startReportage}>Let's start</button>
                    </p>
                </>
            );

        return (
            <article className="Intro">
                <div className="Page">
                    <div className="Intro-top">
                        <h4>Fulvio Risuleo</h4>
                    </div>

                    <div
                        className={`Intro-center${
                            loading === false ? " hide" : ""
                        }`}
                        style={{ width: percent + "%" }}
                    >
                        <div className="Intro-dino"></div>
                    </div>

                    <div
                        className={`Intro-logo ${
                            loading === false && show === "logo"
                                ? " active"
                                : ""
                        }`}
                    >
                        <h1>Reportage Bizarre</h1>
                    </div>

                    <div
                        className={`Intro-langs ${
                            loading === false && show === "langs"
                                ? " active"
                                : ""
                        }`}
                    >
                        <button onClick={() => this.selectLang("it")}>
                            IT
                        </button>{" "}
                        |{" "}
                        <button onClick={() => this.selectLang("en")}>
                            EN
                        </button>
                    </div>

                    <div
                        className={`Intro-phrase ${
                            loading === false && show === "phrase"
                                ? " active"
                                : ""
                        }`}
                    >
                        {introText}
                    </div>

                    <div className="Intro-bottom">
                        <div className="icon icon-headphone" />
                        {this.props.AppStore.state.lang === "it" ? <p>Ti consigliamo le cuffie</p> : <p>We recommend headphones</p>} 
                    </div>
                </div>
            </article>
        );
    }
}

export default Intro;
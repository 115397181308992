import React, { Component } from "react";
import Sound from 'react-sound';

import metroSound from '../../assets/metro.mp3'

import "./AudioPlayer.scss";

class AudioPlayer extends Component {

  componentWillMount() {
      clearInterval(this.increaseVolume);
      clearInterval(this.decreaseVolume);
  }

//   handleSongPausing = () => {
//       this.decreaseVolume = setInterval(() => {
//         this.setState({ volume: this.state.volume - this.state.delta });
//       }, 100);
//   };

  render() {
    const { AppStore } = this.props;

    return (
        <>
            <Sound
                url={metroSound}
                playStatus={
                    AppStore.state.soundPlaying
                        ? Sound.status.PLAYING
                        : Sound.status.PAUSED
                }
                loop={true}
                volume={AppStore.state.soundVolume}
                onPlaying={AppStore.handleSongPlaying}
                onPause={AppStore.handleSongPausing}
                // playFromPosition={300 /* in milliseconds */}
                // onLoading={this.handleSongLoading}
                // onFinishedPlaying={this.handleSongFinishedPlaying}
            />
            {!AppStore.state.playing ? (
                <button
                    className={`AudioPlayer-button ${
                        AppStore.state.soundPlaying
                            ? "AudioPlayer-button--active"
                            : ""
                    }`}
                    onClick={() =>
                        AppStore.changeAudioStatus(
                            !AppStore.state.soundPlaying,
                            AppStore.state.soundPlaying && true
                        )
                    }
                >
                    <span>Audio on/off</span>
                </button>
            ) : (
                ""
            )}
        </>
    );
  }
}

export default AudioPlayer;

import {Container} from 'unstated';
import history from './history';
import { EPISODES } from './Episodes';
// import axios from 'axios';
import * as ReactGA from './GA';

const SERVER_BASE = process.env.REACT_APP_SERVER;

export default class AppContainer extends Container {
    state = {
        lang: 'it',
        episode: 'all',
        playing: false,
        timeStatuses: {
            1: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            2: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            3: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            4: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            5: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            6: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            7: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            8: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            9: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            10: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            11: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            12: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            13: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            14: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            15: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            16: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            17: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            18: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            19: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
            20: {
                normal: '0',
                totalDuration: '',
                completed: false
            },
        },
        videoJsOptions: {
            autoplay: true,
            controls: true,
            html5: {
                nativeTextTracks: false,
                nativeVideoTracks: false,
                // hls: {
                //   overrideNative: true
                // }
            },
            sources: [{
              src: '',
            //   type: 'application/x-mpegURL'
              type: 'video/mp4'
            }],
            poster: ''
        },
        loading: true,
        completed: false,
        firstMapLoad: true,
        route: '/',
        hoverMap: '',
        mouseMoving: true,
		soundPlaying: false,
		soundVolume: 0,
		soundDelta: 5,
		soundMutedByUser: false  
    };

    // loadings
    changeLoadingState = (state) => {
        this.setState({
            loading: state
        })
    }

    setLang = (lang) => {
        console.log('change lang');
        this.setState({
            lang: lang,
        });
    }

    changeMapLoadingState = (state) => {
        this.setState({
            firstMapLoad: state
        })
    }
    // loadings

    // routing
    setRoute = (route) => {
        this.setState({
            route: route
        })
    }
    // routing

    // misc
    updateChars = (component) => {
        if (component === 'all' && this.state.char !== 'all') {
            this.setState({
                episode: 'all',
                playing: false,
            });
        } else {
            if (this.state.episode !== component) {
                this.setState({
                    episode: component,
                    playing: true,
                });

                let currentSrc = EPISODES[this.state.lang][component.toLocaleLowerCase()].src;
                let currentPoster = EPISODES[this.state.lang][component.toLocaleLowerCase()].poster;
                let videoJsOptions = { ...this.state.videoJsOptions };

                videoJsOptions['sources'][0].src = currentSrc;
                videoJsOptions['poster'] = currentPoster;

                this.setState({
                    videoJsOptions
                });
            }
        }
    }

    hoverMap = (id) => {
        this.setState({
            hoverMap: id
        })
    }

    setMouseMove(e) {
        e.preventDefault();
        this.setState({mouseMoving: true});

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ mouseMoving: false })
        }, 3000);
    }

    changeAudioStatus = (status, fromUser) => {
        this.setState({
			soundPlaying: status,
			soundMutedByUser: fromUser ? fromUser : this.state.soundMutedByUser
        });
	}
	
	handleSongPlaying = () => {
		this.state.soundVolume < 100 &&
		  this.setState({ soundVolume: this.state.soundVolume + this.state.soundDelta })
	};
	handleSongPausing = () => {
		this.setState({ soundVolume: 0 });
	};
    // misc

    // player
    handlePlaying = (name) => {
        this.updateChars(name.toLocaleLowerCase());
		history.push(`/map/${name}`);
		
		this.changeAudioStatus(false);

        // reset analytics times
        let analyticsTimes = {...this.state.analyticsTimes}
        analyticsTimes.ten = false;
        analyticsTimes.quarter = false;
        analyticsTimes.half = false;
        analyticsTimes.threeQuarters = false;

        this.setState({
            episode: name,
            playing: true,
            analyticsTimes
        }, () => {
            // ReactGA.pageview(`en/indagati/${name}`);
        });
    }

    checkIfCompleted = () => {
        const timesStatuses = { ...this.state.timeStatuses };
        let completedCount = 0;

        Object.keys(timesStatuses).map((timeStatus) => {
            if(timesStatuses[timeStatus].completed) {
                completedCount++;
            }
        });

        if (completedCount >= 20) {
            this.setState({
                completed: true,
                soundVolume: 0,
                soundPlaying: false,
            });
        }
        console.log("check if completed", completedCount);
    }

    resetPlayer = () => {
        let videoJsOptions = { ...this.state.videoJsOptions };

        videoJsOptions['sources'][0].src = '';
        videoJsOptions['poster'] = '';

        this.setState({
            episode: 'all',
            playing: false,
            videoJsOptions
        });
    }

    closePlayer = () => {

        this.setState({
            playing: false,
        }, () => {
            if(this.state.episode !== 'all') {
                ReactGA.event({
                    category: `Video ${this.state.episode}`,
                    action: `Close ${this.state.episode}`,
                });
                history.push('/map/all');
                // ReactGA.pageview(`en/indagati`);
            }
		});

        this.resetPlayer();

		!this.state.soundMutedByUser && this.changeAudioStatus(true);
        // check if we completed
        this.checkIfCompleted();
    }

    updateTimeStatus = (timestamp, duration) => {
        let id = this.state.episode.toLocaleLowerCase();
        let timeStatuses = {...this.state.timeStatuses};
        
        let elapsed = Math.round(timestamp);
        let totalDuration = Math.round(duration);
        let percent = Math.round(elapsed / totalDuration * 100);

        // let analyticsTimes = {...this.state.analyticsTimes}

        // if (!analyticsTimes.ten && percent > 10) {
        //     analyticsTimes.ten = true;
        //     this.setState({
        //         analyticsTimes
        //     }, () => {
        //         ReactGA.event({
        //             category: `Video ${this.state.char}`,
        //             action: 'Complete at 10%',
        //         });
        //     });
        // }
        // if (!analyticsTimes.quarter && percent > 25) {
        //     analyticsTimes.quarter = true;
        //     this.setState({
        //         analyticsTimes
        //     }, () => {
        //         ReactGA.event({
        //             category: `Video ${this.state.char}`,
        //             action: 'Complete at 25%',
        //         });
        //     });
        // }
        // if (!analyticsTimes.half && percent > 50) {
        //     analyticsTimes.half = true;
        //     this.setState({
        //         analyticsTimes
        //     }, () => {
        //         ReactGA.event({
        //             category: `Video ${this.state.char}`,
        //             action: 'Complete at 50%',
        //         });
        //     });
        // }
        // if (!analyticsTimes.threeQuarters && percent > 75) {
        //     analyticsTimes.threeQuarters = true;
        //     this.setState({
        //         analyticsTimes
        //     }, () => {
        //         ReactGA.event({
        //             category: `Video ${this.state.char}`,
        //             action: 'Complete at 75%',
        //         });
        //     });
        // }

        if(timeStatuses[id].normal !== timestamp) {
            timeStatuses[id].normal = timestamp;

            // if is already completed or if is just started 
            if (
                timeStatuses[id].completed ||
                timeStatuses[id].totalDuration === 0
            ) {
                return;
            }
            
            // if is not completed and is going when over 90% set completed true
            if (
                timeStatuses[id].normal > 2 &&
                timeStatuses[id].normal >= timeStatuses[id].totalDuration * 0.8
            ) {
                timeStatuses[id].completed = true;
            }

            this.setState({
                timeStatuses,
            });
        }
    }

    checkDuration = (duration) => {
        let id = this.state.episode.toLocaleLowerCase();
        let timeStatuses = {...this.state.timeStatuses};

        timeStatuses[id].totalDuration = duration;

        this.setState({
            timeStatuses,
        });
    }

    onEnded = () => {
        ReactGA.event({
            category: `Video ${this.state.episode}`,
            action: `Ended ${this.state.episode}`,
        });
    }
    // player
}